import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Cta from '../components/cta'
import OurStoryNavLayout from '../components/our-story-nav-layout'
import { TemplateProps } from '../utils/types'
import { Text, ThemeUIStyleObject } from 'theme-ui'
import { renderBody } from '../utils/sections'

export const query = graphql`
  query OurStory($pageId: String!) {
    page: contentfulPage(id: { eq: $pageId }) {
      ...PageData
    }
  }
`

const titleSx: ThemeUIStyleObject = {
  fontFamily: 'Lubalin Graph Std',
  fontWeight: '600',
  fontSize: '2.5rem',
  marginBottom: '2.5rem',
}

const OurStory: React.FC<TemplateProps> = ({
  data: { page },
  pageContext: {
    navigation,
    footerNavigation,
    helpNavigation,
    ourStoryNavigation,
    ourStoryPageHero,
  },
  location,
}) => {
  const pageHero = page?.pageHero || ourStoryPageHero

  return (
    <Layout
      page={page}
      navigation={navigation}
      footerNavigation={footerNavigation}
      helpNavigation={helpNavigation}
      ourStoryNavigation={ourStoryNavigation}
    >
      {pageHero && <Cta {...pageHero} />}
      <OurStoryNavLayout
        ourStoryNavigation={ourStoryNavigation}
        location={location}
      >
        <Text sx={titleSx}>{page.title}</Text>
        {page.body && renderBody(page)}
      </OurStoryNavLayout>
    </Layout>
  )
}

export default OurStory
