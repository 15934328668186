import React, { ReactElement } from 'react'
import { Box, Flex, Link, ThemeUIStyleObject } from 'theme-ui'
import { NavItemProps } from '../utils/types'
import { WindowLocation } from '@reach/router'

type HelpNavLayoutProps = {
  ourStoryNavigation: NavItemProps[]
  children: ReactElement | ReactElement[]
  location: WindowLocation
}

const wrapperSx: ThemeUIStyleObject = {
  maxWidth: '77.4375rem',
  margin: 'auto',
  marginTop: '2.1875rem',
}

const navWrapperSx: ThemeUIStyleObject = {
  flex: ['0 0 26%'],
  flexDirection: 'column',
  display: ['none', 'none', 'initial'],
  paddingLeft: ['1.5625rem', '1.5625rem', '1.5rem', 0],
  paddingRight: ['1.3125rem', '1.3125rem', '1.3125rem', '2.0625rem'],
}

const contentWrapperSx: ThemeUIStyleObject = {
  flex: ['0 0 100%', '0 0 100%', '0 0 75%'],
  flexDirection: 'column',
  paddingRight: ['1.5rem', '1.5rem', '1.5rem'],
  paddingLeft: ['1.5rem', '1.5rem', 'initial'],
}

const navSx: ThemeUIStyleObject = {
  border: '1px solid #D4D7D7',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '-1px',
  a: {
    paddingY: '1.1875rem',
    fontFamily: 'Open Sans',
    fontSize: '1rem',
    textDecoration: 'none',
    color: 'black',
    lineHeight: '2.109375rem',
    textAlign: 'center',
  },
}

const activeNavSx: ThemeUIStyleObject = {
  ...navSx,
  background: '#9AD43A',
  a: {
    ...navSx.a,
    fontWeight: '600',
  },
}

const HelpNavLayout: React.FC<HelpNavLayoutProps> = ({
  ourStoryNavigation,
  children,
  location,
}) => {
  return (
    <Flex sx={wrapperSx}>
      <Flex sx={navWrapperSx}>
        <Box sx={location.pathname === '/our-story' ? activeNavSx : navSx}>
          <Link href="/our-story">Our story</Link>
        </Box>
        {ourStoryNavigation?.map((navItem: NavItemProps, index: number) => (
          <Box
            sx={location.pathname === navItem?.slug ? activeNavSx : navSx}
            key={`help-nav-${index}`}
          >
            <Link href={navItem?.items?.length ? undefined : navItem?.slug}>
              {navItem.title}
            </Link>
          </Box>
        ))}
      </Flex>
      <Flex sx={contentWrapperSx}>{children}</Flex>
    </Flex>
  )
}

export default HelpNavLayout
